<template>
  <div class="container-openvidu">
    <video
      ref="vid"
      autoplay
      style="width:320px;height:240px"
    />
    <!-- <v-btn
      v-if="afficherUnmute"
      class="btn-unmute-openvidu"
      icon
      @click="unmute"
    >
      <v-icon large>
        mdi-volume-off
      </v-icon>
      <span class="pl-3">
        {{ $t('general.activer_le_son') }}
      </span>
    </v-btn> -->
  </div>
</template>

<script>
  export default {
    name: 'OvVideo',

    props: {
      streamManager: Object,
    },

    data: () => ({
      afficherUnmute: false,
    }),

    mounted () {
      window.monVid = this.$refs.vid
      this.streamManager.addVideoElement(this.$refs.vid)
      this.$refs.vid.play().then(() => {
        // Playing, yay!
        console.error('Playing')
      }).catch(() => {
        console.error('Can\'t play')
        this.$emit('playblocked', true)
        this.$refs.vid.muted = true
        this.$refs.vid.play()
        this.afficherUnmute = true
      })
    },

    methods: {
      unmute () {
        this.$refs.vid.muted = false
        this.afficherUnmute = false
      },
    },
  }
</script>

<style>
.container-openvidu {
  position: relative;
}

.btn-unmute-openvidu {
  position: absolute;
  bottom: 10px;
  left: 60px;
  color: white !important;
}
</style>
